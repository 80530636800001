import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import moment from "moment"
import Slider from "react-slick"

import Layout from "../mainComponents/Layout"
import Footer from "../mainComponents/Footer"
import FooterMail from "../components/FooterMail"
import SEO from "../mainComponents/seo"
import useResize from "../constants/useResize";

import makeUrlValid from "../components/makeUrlValid"
import Wrapper from "../mainComponents/Wrapper"
import catBlog from "../assets/cat-blog.svg"
import animals from "../assets/blog slick/ANIMALS.png"
import art from "../assets/blog slick/art room.png"
import burn from "../assets/blog slick/burners.png"
import cyber from "../assets/blog slick/Cyberpunk_cycles6.png"
import fashion from "../assets/blog slick/Fashion.png"
import movadesk from "../assets/blog slick/movadesk.png"
import estate from "../assets/blog slick/Real Estate.png"
import smart from "../assets/blog slick/Smart home.png"
import itmarket from "../assets/blog slick/Ukrainian IT Market_.png"

import "../style/blog.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import axios from "axios"

export const query = graphql`
  query ArticlesQuery {
    allArticles: allStrapiArticles(sort: {order: DESC, fields: created_at}) {
      edges {
        node {
          strapiId
          created_at
          Preview {
            publicURL
          }
          Title
          Short_text
          categories {
            id
            Tag
          }
          author {
            Photo {
              publicURL
            }
            Name
            Position
          }
        }
      }
    }
    articleCategories: allStrapiArticles {
      edges {
        node {
          categories {
            id
            Tag
          }
        }
      }
    }
    latestArticle: allStrapiArticles(sort: {order: DESC, fields: created_at}, limit: 1) {
      edges {
          node {
            Title
            Subtitle
            created_at
            Short_text
            Preview {
              publicURL
            }
            categories {
              id
              Tag
            }
            background_image {
              publicURL
            }
          }
      }
    }
  }
`

const BlogPage = ({ data }) => {
  const [activeCategories, setActiveCategories] = useState([])
  const [categories, setCategories] = useState([])
  const [subscribeEmail, setSubscribeEmail] = useState(null)
  const {innerWidth} = useResize()

  const articles = data.allArticles.edges
  const article = data.latestArticle.edges[0].node
  const articlesRow = data.allArticles.edges.slice(0, 2)
  const settings = {
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: innerWidth >= 1380 ? 4 : innerWidth >= 900 ? 3 : innerWidth >= 500 ? 2 : 1,
    slidesToScroll: 1,
  }
  useEffect(() => {
    let uniqueArr = []

    data.articleCategories.edges
      .map(i => i.node.categories)
      .flat()
      .forEach(i => {
        if (!uniqueArr.find(j => j.id === i.id))
          uniqueArr.push(i)
      })

    setCategories(uniqueArr)
  }, [])

  const checkIfHasCategories = categories => {
    let count = 0
    categories.forEach(i => {
      if (activeCategories.includes(i.id ?? i)) {
        count += 1
      }
    })

    return count === activeCategories.length
  }


  const itemsAll = articles.filter(({ node }) => checkIfHasCategories(node.categories)).length
  const [itemsShow, setItemsShow] = useState(itemsAll < 7 ? itemsAll : 12)

  // Pagination
  // const [itemsShow, setItemsShow] = useState(itemsAll < 10 ? itemsAll : 9);


  const showMore = () => {
    if (itemsAll < 7) {
    } else {
      setItemsShow(itemsShow + 12)
    }
  }

  const mapArticles = list => {
    return list.filter(({ node }) => list.length > 2 ? checkIfHasCategories(node.categories) : true).map(({ node }, index) => itemsShow > index ? (
      <Link to={`/blog/article/${makeUrlValid(node.Title)}`} className="blog-item" key={node.strapiId}>
        <div className="blog-item__block">
          <img
            className="blog-item__image"
            src={node.Preview.publicURL}
            alt=""
          />
        </div>
        <h5 className="blog-item__title">{node.Title}</h5>

        <span className="blog-item__shorttext">{node.Short_text}</span>

        <span className="blog-head-date">
                  {moment(node.created_at).format("LL")}
                </span>
        <div className="blog-head-tags">
          {node.categories.map(elem => (
            <span
              key={elem.id}
              className={`blog-head-tag ${list.length > 2 && activeCategories.includes(elem.id) ? "active-category" : ""}`}
            >
                      {elem.Tag.toLowerCase()}
                    </span>
          ))}
        </div>
      </Link>
    ) : null)
  }
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <Wrapper>
      <Layout>
        <SEO title="Blog" description={"Check out our eye-catching blog!"} />

        <section className="blog">
          <div className="blog__wrapper container">
            <h3 className="blog__subtitle mobile">Movadex blog</h3>

            <Link to={`/blog/article/${makeUrlValid(article.Title)}`} className="blog-head">
              <div className="blog-head-img"
                   style={{ backgroundImage: `url(${article.Preview.publicURL})` }}>
              </div>
              <div className="blog-head-content">
                <span className="blog-head-date">
                  {moment(article.created_at).format("LL")}
                </span>
                <span className="blog-head-title">
                  {article.Title}
                </span>
                <span className="blog-head-subtitle">
                  {article.Subtitle}
                </span>

                <span className="blog-item__shorttext">{article.Short_text}</span>
                {/*<span className="blog-head-description">*/}
                {/*  {article.Subtitle}*/}
                {/*</span>*/}
                <div className="blog-head-tags">
                  {article.categories.map(elem => (
                    <span
                      key={elem.id}
                      className={`blog-head-tag`}
                    >
                      {elem.Tag.toLowerCase()}
                    </span>
                  ))}
                </div>
              </div>
            </Link>

            <h3 className="blog__subtitle">Latest</h3>

            {/*TODO: в этой части размер текста должен быть больше*/}
            <div className="blog__block blog-list row-list">
              {mapArticles(articlesRow)}
            </div>
            <div className="blog-slider-section">
              <div onClick={(() => {
                window.open("https://dribbble.com/movadex", "_blank")
              })} className="trending-blog">
                <span>Be in touch with trending web design</span>
                <div className="portfolio-slider">
                  <Slider {...settings}>
                    <div>
                      <img className="slider-image" src={animals} alt="Cat" />
                    </div>
                    <div>
                      <img src={art} alt="Cat" />
                    </div>
                    <div>
                      <img src={burn} alt="Cat" />
                    </div>
                    <div>
                      <img src={cyber} alt="Cat" />
                    </div>
                    <div>
                      <img src={fashion} alt="Cat" />
                    </div>
                    <div>
                      <img src={movadesk} alt="Cat" />
                    </div>
                    <div>
                      <img src={estate} alt="Cat" />
                    </div>
                    <div>
                      <img src={smart} alt="Cat" />
                    </div>
                    <div>
                      <img src={itmarket} alt="Cat" />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>

            <h3 className="blog__subtitle">More articles</h3>

            <div className="blog-head-tags category-filter">
              <span onClick={() => setActiveCategories([])}
                    className={`blog-head-tag ${!activeCategories.length ? "active-category" : ""}`}>Show all</span>
              {categories.map(elem => (
                <span key={elem.id}
                      onClick={() => setActiveCategories(state => state.includes(elem.id) ? state.filter(i => i !== elem.id) : [...state, elem.id])}
                      className={`blog-head-tag ${activeCategories.includes(elem.id) ? "active-category" : ""}`}>
                      {elem.Tag.toLowerCase()}
                    </span>
              ))}
            </div>

            <div className="blog__block blog-list">
              {mapArticles(articles)}
            </div>
          </div>

          <div className="subscribe-section">
            <img className="under-dog" src={catBlog} alt="Cat Blog" />
            <div className="subscribe-block">
              <div className="header-title">
                <span
                  className="quiz-title">Want to read more interesting articles and enjoy beautiful illustartions?</span>
                <span className="main-title">Subscribe to our blog!</span>
              </div>
              <div className="footer-form-email">
                <input className="subscribe-input" placeholder={"me@example.com"} type="text" value={subscribeEmail} onChange={(e)=>{setSubscribeEmail(e.target.value)}} />
                <button className="subscribe-button" onClick={()=>{if (validateEmail(subscribeEmail)) {
                  axios.put("https://api.sendgrid.com/v3/marketing/contacts", {
                    "list_ids": [
                      "85f4d9ea-a652-4ef0-90f8-3b598deac794"
                    ],
                    "contacts": [
                      {
                        "email": subscribeEmail
                      }
                    ]
                  }, {
                    headers: {
                      'Content-Type': 'application/json',
                      "Authorization": "Bearer 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"
                    }
                  })
                    .then(res => {
                      console.log(res)
                      alert("Thank you for subscription!")
                      setSubscribeEmail("")
                    })
                    .catch(e => {
                      console.log(e)
                      alert("Thank you for subscription!")
                      setSubscribeEmail("")
                    })

                }
                else {alert("Please check the email field.")}}}>Subscribe</button>
              </div>
            </div>
          </div>

          {itemsAll > itemsShow ?
            <div className="blog-more" onClick={showMore}>
              <button className="blog-more__btn">Show more</button>
            </div> : null}
        </section>
      </Layout>

      <Footer>
        <FooterMail />
      </Footer>
    </Wrapper>
  )
}

export default BlogPage
